export const prescribeOnSiteModal = {
  PrescribeOnSite: "{{treatmentPlanTermPresentParticiple}} on-site",
  UsingPrescribingTool:
    "Use Fullscript to better communicate with all of your {{patientTermPlural}} — whether you want them to order from Fullscript or not! Write a {{treatmentPlanTerm}}, and use the on-site toggle to indicate you’ve dispensed products in-person. Find it in the customize tool section on the {{treatmentPlanTerm}} page.",
  YouAndYourPatient:
    "You and your {{patientTerm}} will receive a digital copy of the {{treatmentPlanTerm}} so you can refer to it at any time, from any device. Over time, you’ll build a consistent, digital record of your {{patientTerm}}s’ {{treatmentPlanTerm}} history.",
  EvenWhenPatients:
    "Even when {{patientTermPlural}} purchase from you in-office, if they receive a virtual {{treatmentPlanTerm}}, they’ll get automated refill reminders from Fullscript based on their dosage. This improves adherence and keeps your {{treatmentPlanTermPlural}} top-of-mind.",
  OutOfStock:
    "Whether you’re out of stock or out of town, Fullscript has your back! Sometimes, it’s impossible to refill a product for a {{patientTerm}} when they need it. Luckily, they’ll already have a copy of your {{treatmentPlanTerm}}, and can easily refill. This ensures they still purchase the supplements from you — and you get visibility into their compliance.",
  OnceYouveUploaded:
    "Once you’ve uploaded your {{patientTerm}} list — even if you dispense on-site — Fullscript can do wonders for {{patientTerm}} engagement: <br /><br />• We’ll send them monthly wellness content, curated by our medical advisory team. {{patientTermCapitalizedPlural}} love these articles, and participating practitioners see a 15% boost in MSRP week over week, on average. br /> br /> • You can easily decide to participate in {{patientTerm}} promotions! We run these several times a year, and take care of all the marketing and logistics. br /> br />• You can use shareable protocols to promote your practice and drive passive revenue from general health recommendations. {{patientTermCapitalizedPlural}} can self-serve online while you spend time doing individualized, in-person care.",

  AccessPatientHistory: "Access {{patientTermCapitalized}} History",
  BetterAdherenceRates: "Better Adherence Rates",
  BackupPlan: "A fail-safe backup plan",
  KeepPatientsEngaged: "Keep {{patientTermPlural}} engaged",

  // Buttons
  SeeManyBenefits: "See the many benefits",
  SeeMoreBenefits: "See more benefits",
  StartPrescription: "Start {{treatmentPlanTerm}}",
  Close: "Close",
  ViewDashboard: "View dashboard",
  OrderDetails: "Order details",
  BackToProcessedOrder: "Back to processed order",
} as const;
