const sharedSettings = {
  tax: {
    addressInfo: {
      streetAddress: "Street address",
      streetAddress2: "Apartment, suite, etc.",
      city: "City",
      postalCode: "Postal code",
      zipcode: "Zip code",
      province: "Province",
      state: "State",
      phoneNumber: "Phone number",
      phoneTooltip:
        "Providing this information lets our team give our users a <2>faster</2> support experience!",
    },
    businessDetails: {
      businessNumber: "Business number",
    },
    legalInfo: {
      businessType: "Business type",
      legalBusinessName: "Legal business name",
      ein: "Your 9 digit EIN",
      einDescription:
        "Your Employee Identification Number also known as a Tax Identification Number.",
      businessNumber: "Your 9 digit Business Number",
      classification: "Classification",
      einSSN: "EIN/SSN",
    },
    bankInfo: {
      accountNumber: "Account number",
      accountNumberTooltip:
        "Your <1>account number</1> is typically found on checks provided by your bank.",
      routingNumber: "Routing number",
      routingNumberTooltip:
        "Your <1>routing number</1> is typically found on checks provided by your bank.",
      transitAndInstitutionNumber: "Transit/branch & institution number",
      transitAndInstitutionNumberToolTip:
        "Your <1>Transit/branch and institution number</1> are typically found on checks provided by your bank.",
    },
  },
  profile: {
    avatar: {
      AvatarFor: "Avatar for: ",
      UploadNew: "Upload new",
      UploadNewPhoto: "Upload new photo",
      NoAvatarPhoto: "No avatar photo",
      AvatarPhoto: "Avatar photo for {{fullName}}",
      EditPhoto: "Edit photo",
      EditAvatarPhoto: "Edit avatar photo",
      EditAvatar: "Edit avatar",
      UploadNewAvatar: "Upload new avatar",
      RemoveAvatar: "Remove avatar",
      RemovePhoto: "Remove photo",
      SavePhoto: "Save photo",
      AvatarError: "Something went wrong while processing your avatar. Please try again!",
    },
  },
} as const;

export { sharedSettings };
