import { useContext } from "react";

import type { ToastData } from "./ToastContext";
import { ToastContext } from "./ToastContext";

const useToast = (): ToastData => {
  return useContext(ToastContext);
};

export { useToast };
