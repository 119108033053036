import { css, type Theme } from "@emotion/react";
import type { ColorProfileTheme } from "aviary-tokens/dist/types";

import { dimensions } from "@styles";
import { cardShadow } from "@styles/emotion-styles/baseStyles";

export const box = (theme: Theme) => css`
  display: flex;
  flex-direction: column;
  background-color: ${theme.surface.level1};
  border-radius: ${dimensions.cardRadius};
  ${cardShadow(theme)};

  color: ${theme.text.body};

  padding: 2rem;

  @media screen and (max-width: ${dimensions.phoneLargeMax}) {
    padding: 1rem;
  }

  &:not(:last-child) {
    margin-bottom: ${dimensions.containerMarginMobile};
  }
`;

export const gutterless = css`
  @media screen and (max-width: ${dimensions.tabletMax}) {
    margin-left: -${dimensions.containerMarginMobile};
    margin-right: -${dimensions.containerMarginMobile};
  }
`;

export const themedColorStyles = (profileTheme: ColorProfileTheme) => css`
  background-color: ${profileTheme.backgroundBox};
  box-shadow: none;
`;

export const system = (theme: Theme) => css`
  background-color: ${theme.system.backgroundMuted};
`;

export const borderColor = (profileTheme: ColorProfileTheme) => css`
  border: 1px solid ${profileTheme.borderBase};
`;

export const padding = {
  none: css`
    padding: 0;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 0;
    }
  `,
  xxsmall: css`
    padding: 0.5rem 1rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 0.25rem 0.5rem;
    }
  `,
  xsmall: css`
    padding: 1rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 0.5rem;
    }
  `,
  small: css`
    padding: 1.5rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1.5rem 1rem;
    }
  `,
  normal: css`
    padding: 2rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1rem;
    }
  `,
  large: css`
    padding: 2.5rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1.25rem;
    }
  `,
  xlarge: css`
    padding: 3rem;

    @media screen and (max-width: ${dimensions.phoneLargeMax}) {
      padding: 1.5rem;
    }
  `,
};
