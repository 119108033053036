/* eslint-disable no-restricted-imports, @fullscript/cross-reference */
import * as remix from "@remix-run/react";
import * as router from "react-router-dom";

/**
 * A hook wrapping useLocation from Remix and react-router-dom
 * It can be used in interchangeably in a Remix or react-router-dom based SPA and work as expected
 * Means that devs don't need to worry about where an app is running and what API to use
 *
 * @returns - either a Remix or react-router-dom based location
 */
const useLocation = (): remix.Location | router.Location => {
  let routerLocation: router.Location;
  let remixLocation: remix.Location;
  let routerError: Error;
  let remixError: Error;

  // Order is important here, we default to trying the react-router-dom version
  // If it does not throw, we know we are operating in some sort of nested router during the Remix migration
  // Otherwise if it fails, we know we are on a Remix only page
  try {
    routerLocation = router.useLocation();
  } catch (e) {
    routerError = e;
  }

  try {
    remixLocation = remix.useLocation();
  } catch (e) {
    if (!routerLocation) {
      remixError = e;
    }
  }

  if (routerError && remixError) {
    throw routerError;
  }

  return routerLocation || remixLocation;
};

export { useLocation };
