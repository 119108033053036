/* eslint-disable @typescript-eslint/naming-convention */

export const sharedCommon = {
  close: "Close",
  downloadOnAppStore: "Download on the App Store",
  nowOnIos: "Now on iOS",
  downloadOnGooglePlay: "Download on Google Play",
  Labs: "Labs",
  // Impersonation Terms
  Impersonating: "Impersonating \xA0",
  StopImpersonatingTitle: "Stop impersonating",
  stopImpersonating: "Stop impersonating and go back to admin",
  operatingAs:
    "You are operating as <1>{{- userFullName}}'s</1> profile. <3>$t(stopImpersonating)</3>",
  InvalidDOB: "Invalid date of birth",
  AddToCart: "Add to cart",
  SelectADifferentSize: "Select a different size",
  AddedToCart: "Added to cart",
  ViewCart: "View Cart",
  QuantityAdded: "{{quantity}} added!",
  OutOfStock: "Out of stock",
  PerUnit: "/ Unit",

  ProductOutOfStockOrDiscontinued:
    "This product cannot be purchased or prescribed because it is out of stock or discontinued",

  Sku: "SKU: {{sku}}",
  dosage: "Dosage",

  practitionerTerm: "practitioner",
  practitionerTermPlural: "practitioners",

  // TreatmentPlan Terms
  prescription: "prescription",
  prescription_plural: "prescriptions",
  prescription_capitalized: "Prescription",
  prescription_capitalized_plural: "Prescriptions",
  prescription_present_participle: "prescribing",
  prescription_present_participle_capitalized: "Prescribing",
  prescription_past_participle: "prescribed",
  prescription_past_participle_capitalized: "Prescribed",
  prescription_verb: "prescribe",
  prescription_verb_capitalized: "Prescribe",
  recommendation: "recommendation",
  recommendation_plural: "recommendations",
  recommendation_capitalized: "Recommendation",
  recommendation_capitalized_plural: "Recommendations",
  recommendation_present_participle: "recommending",
  recommendation_present_participle_capitalized: "Recommending",
  recommendation_past_participle: "recommended",
  recommendation_past_participle_capitalized: "Recommended",
  recommendation_verb: "recommend",
  recommendation_verb_capitalized: "Recommend",

  // Patient Terms
  client: "client",
  client_plural: "clients",
  client_possessive: "client's",
  client_capitalized: "Client",
  client_capitalized_plural: "Clients",
  client_capitalized_possessive: "Client's",
  patient: "patient",
  patient_plural: "patients",
  patient_possessive: "patient's",
  patient_capitalized: "Patient",
  patient_capitalized_plural: "Patients",
  patient_capitalized_possessive: "Patient's",

  USA: "USA",
  CAN: "CA",
  UnitedStates: "United States",
  Canada: "Canada",
  Back: "Back",
  Next: "Next",
  Skip: "Skip",
  SkipForNow: "Skip for now",
  Done: "Done",
  Submit: "Submit",
  BackToCatalogHome: "Back to catalog home",

  EmptySimilarProducts: "It looks like there are no <1>similar</1><2/><3>products</3> available",
  Description: "Description",

  SizeOfVariant: "<0>Size:</0> {{variantDescriptor}}",

  // Duration
  DurationOptional: "Not selected",
  Duration: "Duration",
  UntilSymptomsResolve: "Until symptoms resolve",
  Ongoing: "Ongoing",
  For1Week: "For 1 week",
  For2Weeks: "For 2 weeks",
  For3Weeks: "For 3 weeks",
  For1Month: "For 1 month",
  For2Months: "For 2 months",
  For3Months: "For 3 months",
  For6Months: "For 6 months",

  // Frequency
  OncePerDay: "Once / day",
  TwicePerDay: "Twice / day",
  ThreeTimesPerDay: "Three times / day",
  FourTimesPerDay: "Four times / day",
  EveryMorning: "Every morning",
  EveryNight: "Every night",
  OnceADay: "once per day",
  PerDay: "per day",
  AsNeeded: "As needed",

  // Dates
  January: "January",
  February: "February",
  March: "March",
  April: "April",
  May: "May",
  June: "June",
  July: "July",
  August: "August",
  September: "September",
  October: "October",
  November: "November",
  December: "December",

  All: "All",
  brands: "brands",
  Brands: "Brands",
  Brand: "Brand",
  Popular: "Popular",
  Filters: "Filters",
  CloseFilters: "Close filters",
  RemoveIngredient: "Remove ingredient",
  SearchIngredients: "Search ingredients...",
  NoResults: "No results to show...",
  Ingredient: "Ingredient",
  Ingredients: "Ingredients",
  SubTypesOf: "Subtypes of {{- ingredient}}",
  ShowAll: "Show all",
  SearchProducts: "Search for products...",
  SearchAll: "Search for products, brands, ingredients, and more...",
  servings: "Serving(s)",
  Condition: "Condition",
  SupplementType: "Supplement type",
  SearchFor: "Search for products named ",
  LoadMoreFilters: "Load more {{title}}",
  ShowMore: "Show more",
  ShowLess: "Show less",
  ShowMoreEllipsis: "... Show more",
  ShowLessEllipsis: "... Show less",
  Remove: "Remove",
  RemoveAll: "Remove all",
  RemoveItem: "Remove {{- item}}",
  Compare: "Compare",
  CompareProducts: "Compare products",
  Edit: "Edit",
  Save: "Save",
  Result: "{{count}} result",
  Result_plural: "{{count}} results",
  CatalogSearchTitleShowing: "Showing",
  CatalogSearchTitleOutOf: "out of",
  CatalogSearchTitleResult: "result",
  CatalogSearchTitleResultFor: "result for",
  CatalogSearchTitleResults: "results",
  CatalogSearchTitleResultsFor: "results for",
  CatalogSearchTitleZero: "No results found",
  CatalogSearchTitleZeroWithQuery: "No results found for",
  LoadMore: "Load more",
  Sort: "Sort",
  Common: "Common",
  Confirm: "Confirm",
  Cancel: "Cancel",
  Manage: "Manage",
  Select: "Select",
  PreviouslyOrdered: "Previously ordered",
  PrimaryProduct: "Primary product",
  Featured: "Featured",
  NumOfProductsSelected: "{{count}}/4 products selected",
  OnSale: "On sale",
  TotalDiscount: "Total discount",
  Backordered: "Backordered",
  Discontinued: "Discontinued",
  Form: "Form",
  Allergens: "Allergens",
  Demographic: "Demographic",
  Certifications: "Certifications",
  More: "More",
  ShowAllAttachments: "ShowAllAttachments",
  LessThan: "Less than or equal",
  GreaterThan: "Greater than or equal",
  EqualTo: "Equal to",
  AnyValue: "Any value",
  Clear: "Clear",
  Clearall: "Clear all",
  ClearFilters: "Clear filters",
  ClearAllSearchFilters: "Clear all search filters",
  DidYouMeanThisEmail: "Did you mean <1>{{suggestedEmail}}</1>?",
  discountPill: "{{discount}}% off orders",
  SelectSuggestedEmail: "Select suggested email",
  EmailRequired: "Email is required",
  EmailMustHaveAt: `Email must include "@"`,
  EmailMustHaveDot: `Email must include "."`,
  EmailInvalidFormat: `Email is invalid format`,
  Email: "Email",
  EmailAddress: "Email address",
  totalDiscount: "{{discount}}% total discount applied to all products",
  cartDiscountBanner: "Your practitioner has applied a {{discount}}% discount to all products",
  promotionTotalDiscountBanner:
    "Total discount is now {{discount}}% on all products for a limited time",
  MyAccount: "My account",
  footerTerms:
    "This page is protected by reCAPTCHA, and subject to the <1>Google Privacy Policy</1> and <3>Google Terms of Service</3>",

  lastFourDigits: "last four digits",
  CreditCardEndingIn: "Credit card ending in {{lastFourDigits}}",
  cardNumber: "Card number",
  expiry: "expiry",
  expiryDate: "Expiry date",
  expiryLabel: "EXP",
  Exp: "Exp. {{month}}/{{year}}",
  CVC: "CVC",
  mmYY: "MM/YY",
  missingBilling: "Missing billing",
  update: "Update",
  holdOn: "Hold on!",
  exitWithoutSaving:
    "You are about to exit this page without saving. If you proceed all information you have provided up to this point will be lost. Are you sure you want to proceed?",
  finishAndSave: "Finish then save",
  leaveWithoutSaving: "Leave without saving",
  Loading: "Loading",
  Dismiss: "Dismiss",
  createdIn: "Created in {{sourcePlatform}}",
  orderedIn: "Ordered in {{sourcePlatform}}",
  PractitionerAvatar: "Avatar for Practitioner",
  userAvatar: "User avatar",
  AvatarFor: "Avatar for {{- firstName}} {{- lastName}}",
  takeTheTour: "Take the tour",
  beta: "BETA",
  Interpunct: "•",
  youSave: "You save",
  Savings: "Savings",
  Notifications: "Notifications",
  Step: "Step",
  PopularProducts: "Popular Products",
  viewProductDetails: "View product details",
  copyLink: "Copy link",
  linkCopied: "Link copied",

  // Delivery
  Estimated: "Estimated {{arrivalDate}}",
  EstimatedArrival: "Estimated arrival {{arrivalDate}}",
  DeliveryOptionAccessibleLabel: "Delivery Method: {{method}} - Cost: {{cost}}",
  BestOptionForHeatSensitive:
    "Best shipping option for <heatSensitive>heat-sensitive products</heatSensitive>",
  freeFirstUppercase: "Free",

  // Labs
  Sent: "Pending payment",
  Paid: "Paid",
  RequisitionReadySimple: "Requisition ready",
  ResultsIn: "Results in",
  Shipped: "Shipped",
  ChangesSaved: "Changes saved",

  // Checkout
  EnteredAddress: "Entered address",
  VerifiedAddress: "Verified address",

  restrictedAccess: "Restricted access",
  FailedToCopyText: "Failed to copy to clipboard",
  CopiedToClipboard: "Copied to clipboard",
  VideoError: "Your browser doesn't support HTML video.",

  // Shipping address drawer
  shippingAddress: "Shipping address",
  saveShippingAddress: "Save shipping address",
  defaultAddress: "Default address",
  defaultAddressDescription:
    "This address will be selected automatically next time you make a purchase.",
  useAsAutoRefillDefaultAddress: "Use as auto-refill shipping address",
  useAsAutoRefillDefaultAddressDescription:
    "Your auto-refills are shipping to an address different than the one you’ve listed above. Would you like to also update your auto-refill address?",
  closeDrawer: "Close drawer",
  country: "Country",
  streetAddress: "Street address",
  suiteEtc: "Suite, etc. (optional)",
  selectState: "Select state",
  state: "State",
  city: "City",
  zipCode: "Zip code",

  // Shipping address
  default: "Default",
  viewMoreOptions: "View more options",
  delete: "Delete",
  saveAsDefault: "Save as default",
  selectableAddressAriaLabel: "Select address: {{address}}",
  deleteAddress: "Delete address",
  deleteAddressConfirmation: "Are you sure you want to delete the address {{address}}?",
  deleteAddressSuccess: "The address has been successfully deleted from your account.",
  deleteAddressError:
    "An error has occurred while attempting to delete the address. Please try again or contact support.",
  shippingAddressSaved: "Your shipping address has been saved.",
  defaultAddressUpdated: "Your default address has been updated.",

  // Payment method
  selectablePaymentMethodAriaLabel: "Select {{ cardType }} card ending in {{ lastDigits }}",
  deletePaymentMethod: "Delete payment method",
  deletePaymentMethodConfirmation: "Are you sure you want to delete this card from your account?",
  deletePaymentMethodSuccess: "Your card has been successfully deleted from your account.",
  deletePaymentMethodError:
    "An error has occurred while attempting to delete your card. Please try again or contact support.",

  // Payment method drawer
  paymentMethod: "Payment method",
  billingAddress: "Billing address",
  sameAsShippingAddress: "Same as shipping address",
  defaultPaymentMethod: "Default payment method",
  defaultPaymentMethodDescription:
    "This card will be selected automatically next time you make a purchase.",
  addNewPaymentMethod: "Add new payment method",
  firstName: "First name",
  lastName: "Last name",

  // Address and Payment Method Management
  AddNewPaymentMethod: "+ Add new payment method",
  AddNewShippingAddress: "+ Add new shipping address",
  ShipTo: "Ship to",
  PaymentMethod: "Payment method",
  NoShippingAddresses: "You have no shipping addresses added to your account.",
  NoPaymentMethods: "You have no payment methods added to your account.",

  poweredByGoogle: "powered by <1>Google</1>",
  Suggestions: "Suggestions",

  platforms: {
    EmersonEcologics: "Emerson Ecologics",
    Fullscript: "Fullscript",
  },
  toggleAccountProfile: "Toggle profile account settings",
} as const;
