export const sharedAccountSettings = {
  confirmNewPassword: "Confirm new password",
  confirmPasswordError: "Passwords do not match",
  currentPassword: "Current password",
  editPassword: "Edit password",
  modifyEmailContactCS: "To modify your email address, please contact us at {{supportEmail}}",
  newPassword: "New password",
  passwordLengthError: "Must contain at least 8 characters",
  passwordUpdatedSuccess: "Password updated successfully",
  savePassword: "Save password",
  setPassword: "Set password",
  createPassword: "Create your password",
  passwordRequirements: "Your password must be at least 8+ characters long.",
  SetUp: "Set up",
  Manage: "Manage",
  TwoStepVerificationHeading: "2-step verification",
  TwoStepVerificationSubtitle:
    "Secure your account data with a one-time code after sign-in to protect against unauthorized access.",
  MultiplePlatformWarning:
    "Your account is shared between Fullscript and Emerson Ecologics. Changes will affect both platforms.",
  NpiNameMismatchError:
    "If you have an NPI, the name you enter must match the listing in the NPI registry. This will not impact your Fullscript profile.",
} as const;
