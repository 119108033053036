/* eslint-disable @typescript-eslint/naming-convention */
export const variantStatus = {
  DaysToReturn: "(in {{count}} day).",
  DaysToReturn_plural: "(in {{count}} days).",
  AskYourPrac: "Ask your practitioner to provide an alternative product.",
  NEW_BackorderedWithNoReturnDate:
    "This item may take longer to ship. If it isn't available within 21 days after purchase, we'll cancel the order, issue a refund, and inform you by email.",
  BackorderedWithReturnDate: "Size backordered until approx.",
  OLD_BackorderedWithNoReturnDate: "This product is backordered.",
  OutOfStockAvailabilityTag: "Out of stock",
  OutOfStockWithReturnDate: "Out of stock until approx.",
  OutOfStockWithNoReturnDate: "This item is out of stock.",
} as const;
