const sharedOnboarding = {
  select: "Select",
  // Legal Information Fields
  legalFirstName: "Legal first name",
  legalLastName: "Legal last name",
  dateOfBirth: "Date of birth",
  dateOfBirthInfo: "Date of birth must be a date in the past in the following format: YYYY-MM-DD.",
  sinNumber: "SIN number",
  SSN: "SSN",
  fullSSNIsRequired: "Full SSN is required",
  SIN: "SIN",
  fullSINIsRequired: "Full SIN is required",
  ssnTootipInfo:
    "This is required for <2>Stripe's mandatory identity verification.</2> It is securely encrypted and used to confirm your identity and prevent financial fraud.",
  last4DigitsOfSSN: "Last 4 digits of SSN",
  last4DigitsOfSSNRequired: "Last 4 digits of SSN are required",
  bankingInfo: {
    accountHolderName: "Account holder's name",
    accountHeldBy: "Account held by",
    accountType: "Account type",
    company: "Company",
    savings: "Savings",
    checking: "Checking",
    individual: "Individual",
  },
  studentInfo: {
    fieldOfStudy: "Field of study",
    graduationDate: "Graduation date",
    graduationDateInfo:
      "Graduation date must be a future date in the following format: YYYY-MM-DD.",
  },
} as const;

export { sharedOnboarding };
